<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <Head title="Авторизация" />
    <div class="loading authentication-bg">
    <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 ">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xxl-4 col-lg-5">
                    <div class="card">

                        <!-- Logo -->
                        <div class="card-header pt-4 pb-4 text-center" style="background-color: white;">
                            <a href="https://xpresent.ae" target="_blank">
                                <span><img src="/assets/images/logo/logoX.png" alt="" height="60"></span>
                            </a>
                        </div>

                        <div class="card-body p-4">

                            <div class="text-center w-75 m-auto">
                                <h4 class="text-dark-50 text-center pb-0 fw-bold">Авторизация</h4>
                                <p class="text-muted mb-4">Введите ваш email/логин и пароль для доступа в кабинет партнера.</p>
                            </div>

                            <form @submit.prevent="submit">

                                <div class="mb-3">
                                    <label for="emailorlogin" class="form-label">Email или логин</label>
                                    <input class="form-control" id="emailorlogin" type="text" v-model="form.email" required autofocus placeholder="Введите ваш Email или логин">
                                    <InputError class="mt-2" :message="form.errors.email" />
                                </div>

                                <div class="mb-3">
                                    <a href="/forgot-password" class="text-muted float-end"><small>Забыли пароль?</small></a>
                                    <label for="password" class="form-label">Пароль</label>
                                    <div class="input-group input-group-merge">
                                        <input  id="password"
                                                type="password"
                                                v-model="form.password"
                                                required
                                                autocomplete="current-password" class="form-control" placeholder="Введите ваш пароль">
                                        <div class="input-group-text" data-password="false">
                                            <span class="password-eye"></span>
                                        </div>
                                    </div>
                                    <InputError class="mt-2" :message="form.errors.password" />
                                </div>

                                <div class="mb-3 mb-3">
                                    <div class="form-check">
                                        <Checkbox name="remember" v-model:checked="form.remember"  class="form-check-input" id="checkbox-signin" checked />
                                        <label class="form-check-label" for="checkbox-signin">Запомнить меня</label>
                                    </div>
                                </div>

                                <div class="mb-3 mb-0 text-center">
                                    <button class="btn btn-primary" type="submit"> Войти </button>
                                </div>

                            </form>
                        </div> <!-- end card-body -->
                    </div>
                    <!-- end card -->

                    <!--<div class="row mt-3">
                        <div class="col-12 text-center">
                            <p class="text-muted">Нет аккаунта? <a href="pages-register.html" class="text-muted ms-1"><b>Зарегистрируйтесь</b></a></p>
                        </div>
                    </div>-->
                    <!-- end row -->

                </div> <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    </div>
    <!-- end page -->
</template>
<style>
.authentication-bg {
    background-image: url('../../../../public/assets/images/bg-pattern-light.svg');
    background-size: cover;
    background-position: 50%;
}
.text-red-600{
    color: #fa5c7c !important;
}
</style>
